import Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';

type DialogHandlerProps = {
    buttonSelector: string;
    dialogSelector: string;
};

export default class DialogHandler {
    readonly #dialogToggleButton: HTMLElement | null;

    readonly #dialog: Dialog;

    public constructor({ buttonSelector, dialogSelector }: DialogHandlerProps) {
        this.#dialogToggleButton = document.querySelector(buttonSelector) as HTMLElement | null;
        this.#dialog = document.querySelector(dialogSelector) as Dialog;
    }

    public initialize(): void {
        if (!this.#dialogToggleButton || !this.#dialog) {
            return;
        }

        this.#dialogToggleButton.addEventListener('click', this.#openDialog);
    }

    readonly #openDialog = (): void => {
        this.#dialog.showModal();
    };
}
