import { init, replayIntegration, setTag } from '@sentry/browser';
import { FeatureToggle, isFeatureEnabled } from '../../util/featureToggle';

init({
    dsn: process.env.SENTRY_DSN,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    integrations: [replayIntegration()]
});

const isLandlord = document.body.classList.contains('userIsLandlord');
setTag('is-landlord', isLandlord.toString());
setTag('deployment-environment', document.documentElement.dataset.deploymentEnv);

const featuresEnabled = Object.values(FeatureToggle)
    .map((feature): string => (isFeatureEnabled(feature) ? feature : ''))
    .filter(Boolean);

if (featuresEnabled.length) {
    setTag('features-enabled', featuresEnabled.join(','));
}
